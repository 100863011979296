<template>
  <div>
    <!-- Preloader -->
    <!-- Navbar -->

    <Contact/>
    <!-- Main content -->
    <section class="slice bgf5">
      <div class="col-lg-12 t-center">
        <h3 class="h3-cp">SpeedVue轻量可视化软件</h3>
        <p class="lead my-4">
          <a href="javascript:;">三维模型轻量化及在线批注解决方案</a>
        </p>
        <p class="lead my-4" style="width: 80vw;margin: 0 auto;text-align: initial;color: #fff;">
          SpeedVue是一款对主流三维模型进行轻量化处理及在线三维批注、圈阅的专业软件。通过高效的三维模型轻量化处理、极速的浏览体验、便捷的模型批注及圈阅，实现了三维模型的轻量化、在线浏览、批注、圈阅的整套解决方案。
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide v-for="item in dataList" :key="item.alt">
          <img :alt="item.alt" :src="item.img"/>
          <p>{{ item.alt }}</p>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination"></div>
        <div slot="button-prev" class="swiper-button-prev"></div>
        <div slot="button-next" class="swiper-button-next"></div>
      </swiper>
    </section>

    <section class="slice slice-lg" style="padding-top:30px;">
      <div class="container">
        <div class="col-lg-12 t-center">
          <h3>SpeedVue轻量可视化软件优势</h3>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">支持多种格式</h5>
                <p class=" mt-2 mb-0">
                  支持多种主流三维模型轻量化处理<br/>
                  如CATIA、UG、Solidworks、ProE/Creo、Inventor<br/>
                  等工具产生的模型及绘图<br/>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">高效压缩</h5>
                <p class=" mt-2 mb-0">
                  轻量化压缩比大(10:1)<br/>
                  模型轻量化效率高(ms级别)<br/>
                  自动化处理能力强<br/>
                  支持超大模型压缩
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">WEB端在线批注、圈阅三维模型</h5>
                <p class="mt-2 mb-0">
                  BS架构，支持跨平台<br/>
                  支持在主流浏览器直接批注圈阅<br/>
                  与SPLM系统集成，保证数据安全<br/>
                  支持国际化语言</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>

export default {
  name: 'S3DViewer',
  data(){
    return{
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 70,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataList: [
        {img: require('../img/index/3dviewer/note.webp'), alt: '三维批注'},
        {img: require('../img/index/3dviewer/history.webp'), alt: '批注历史'},
        {img: require('../img/index/3dviewer/strcture.webp'), alt: '结构选取'},
        {img: require('../img/index/3dviewer/18i.webp'), alt: '多语言国际化'},
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/my.scss";

.bgf5{
  background: url('../img/login/3.jpg') no-repeat;
  background-size: 100% 100%;
}
</style>
